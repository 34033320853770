import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { useAtom } from "jotai";

import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_SEARCH_KIND } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { ADMIN_FORWARDING_MANAGER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import { SETTLEMENT_PURCHASE_COMPANY_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminSettlement";
import { BooleanStringV2 } from "@sellernote/_shared/src/types/common/common";
import MultiSearchWithTerm from "@sellernote/_shared-for-forwarding-admin/src/components/MultiSearchWithTerm";
import useSearchWithDate from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import useSelectFilter, {
  SelectFilterOptionList,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSelectFilter";
import { SETTLEMENT_ADMIN_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/settlement";

import { useResetAtom } from "jotai/utils";

import {
  BOOLEAN_SELECT_FILTER_OPTION_LIST,
  DATE_SEARCH_TYPE_OPTION_LIST,
  SHIPMENT_TYPE_FILTER_LIST,
  TERM_SEARCH_TYPE_OPTION_LIST,
} from "./constants";

export default function usePurchaseManagementTableFilter() {
  const [purchaseTableSearchWithTermList, setPurchaseTableSearchWithTermList] =
    useAtom(SETTLEMENT_ADMIN_ATOMS.PURCHASE_TABLE_SEARCH_WITH_TERM_LIST);

  const resetPurchaseTableSearchWithTermList = useResetAtom(
    SETTLEMENT_ADMIN_ATOMS.PURCHASE_TABLE_SEARCH_WITH_TERM_LIST
  );

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions: DATE_SEARCH_TYPE_OPTION_LIST,
    });

  const {
    SelectFilterPanel: ForwardingManagerSelectFilterPanel,
    selectFilter: forwardingManagerSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: [
      { label: "전체", value: undefined },
      ...ADMIN_FORWARDING_MANAGER_OPTION_LIST,
    ],
    label: "담당자",
  });

  const {
    SelectFilterPanel: CompanyTypeSelectFilterPanel,
    selectFilter: companyTypeSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: [
      { label: "전체", value: undefined },
      ...SETTLEMENT_PURCHASE_COMPANY_TYPE_OPTION_LIST,
    ],
    label: "구분",
  });

  const {
    SelectFilterPanel: IsWithdrawnSelectFilterPanel,
    selectFilter: isWithdrawnSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: BOOLEAN_SELECT_FILTER_OPTION_LIST,
    label: "출금 유무",
  });

  const {
    SelectFilterPanel: ShipmentTypeSelectFilterOptionList,
    selectFilter: shipmentTypeSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: SHIPMENT_TYPE_FILTER_LIST,
    label: "수출입 구분",
  });

  const changeBooleanStringToBoolean = useCallback(
    (
      booleanString:
        | SelectFilterOptionList<BooleanStringV2 | undefined>
        | undefined
    ) => {
      if (booleanString?.value === "TRUE") {
        return true;
      }

      if (booleanString?.value === "FALSE") {
        return false;
      }

      return undefined;
    },
    []
  );

  const PurchaseSettlementTableFilter = useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 1,
          pt: 1,
          px: 1,
          background: "#fff",
        }}
      >
        {ForwardingManagerSelectFilterPanel}

        {DateSearchPanel}

        {CompanyTypeSelectFilterPanel}

        {IsWithdrawnSelectFilterPanel}

        {ShipmentTypeSelectFilterOptionList}

        <MultiSearchWithTerm
          setMultiSearchWithTermList={setPurchaseTableSearchWithTermList}
          multiSearchWithTermList={purchaseTableSearchWithTermList}
          termSearchTypeOptionList={TERM_SEARCH_TYPE_OPTION_LIST}
          resetMultiSearchWithTermList={resetPurchaseTableSearchWithTermList}
        />
      </Box>
    );
  }, [
    CompanyTypeSelectFilterPanel,
    DateSearchPanel,
    ForwardingManagerSelectFilterPanel,
    IsWithdrawnSelectFilterPanel,
    ShipmentTypeSelectFilterOptionList,
    purchaseTableSearchWithTermList,
    resetPurchaseTableSearchWithTermList,
    setPurchaseTableSearchWithTermList,
  ]);

  // 배열 타입으로 되어있는 필터 전역 데이터를 객체로 변경
  const purchaseSearchWithTermObject = useMemo(() => {
    return purchaseTableSearchWithTermList.reduce((acc, cur) => {
      acc[cur.searchType] = cur.searchTerm;
      return acc;
    }, {} as Record<GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_SEARCH_KIND, string>);
  }, [purchaseTableSearchWithTermList]);

  return {
    PurchaseSettlementTableFilter,
    shipmentTypeSelectFilterValue: shipmentTypeSelectFilter?.value,
    companyTypeSelectFilter: companyTypeSelectFilter?.value,
    isWithdrawnSelectFilterValue: changeBooleanStringToBoolean(
      isWithdrawnSelectFilter
    ),
    dateSearchType,
    startDate,
    endDate,
    purchaseSearchWithTermObject,
    forwardingManagerSelectFilterValue: forwardingManagerSelectFilter?.value,
  };
}
