import { Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";

import useSnackbar from "../../../../../../../../../../hooks/useSnackbar";
import validateForm from "../utils";
import useSaveCargoManifest from "./hooks";

export default function EditAndSaveButton({
  isEditMode,
  setIsEditMode,
  shipmentId,
  form,
  cargoManifestInfo,
  canSubmit,
  isImport,
  deletedIdList,
  setDeletedIdList,
}: {
  isEditMode: boolean;
  /** 함수형 업데이트를 위해 SetStateAction 타입 사용 */
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  shipmentId: number;
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
  canSubmit: boolean;
  isImport: boolean;
  deletedIdList: number[];
  setDeletedIdList: (val: number[]) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const {
    updateCargoManifest,
    ResponseHandlerOfDeleteContainerData,
    ResponseHandlerOfUpdateCargoManifest,
  } = useSaveCargoManifest({
    form,
    cargoManifestInfo,
    shipmentId,
    isImport,
    isEditMode,
    setIsEditMode,
    deletedIdList,
    setDeletedIdList,
  });

  const handleCargoManifestInfoUpdate = () => {
    if (isEditMode && !validateForm({ form, handleSnackbarOpen, isImport })) {
      return;
    }

    updateCargoManifest();
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          background: "coral",
          "&.MuiButtonBase-root:hover": { background: "coral" },
        }}
        onClick={handleCargoManifestInfoUpdate}
        size="small"
        disabled={!canSubmit}
      >
        {isEditMode ? "저장" : "수정"}
      </Button>

      {ResponseHandlerOfDeleteContainerData}
      {ResponseHandlerOfUpdateCargoManifest}
    </>
  );
}
