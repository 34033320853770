import { createTheme } from "@mui/material";

/**
 * 쉽다 Admin, 파트너 Admin에서 사용되는 Mui Component overriding style 정의
 * components 객체 내부 순서는 'Mui' Prefix를 제외한 컴포넌트 명을 알파벳 순서로 정렬
 *
 * @requires className="sads"
 *   - overriding style 적용을 위한 className
 *     - (e.g. <MuiComponent className="sads">)
 * @requires !important
 *   - 아래 정의된 style을 다시 override 해야할 떄 사용
 *     - (e.g. <MuiComponent className="sads" sx={{ color: "red !important" }}>
 *
 * @property {array} variants - defaultProps 속성에 따라 다른 스타일을 override 할 때 사용
 *   - variants > props 객체 조건은 모두 만족해야 아래 style이 적용된다.
 *
 * FIXME: 스타일에 사용된 값 상수로 처리
 */
const theme = createTheme({
  components: {
    MuiAutocomplete: {
      variants: [
        {
          props: { className: "sads", size: "small" },
          style: {
            "& .MuiInputBase-input": {
              fontSize: 14,
            },
          },
        },
      ],

      styleOverrides: {
        root: {
          "&.sads": {
            fontsize: 14,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          "&.sads": {},
        },
      },
      variants: [
        {
          props: { className: "sads", size: "small" },
          style: { padding: "3px 6px", minWidth: 0 },
        },
      ],
    },

    MuiDivider: {
      styleOverrides: {
        root: { "&.sads": {} },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "&.sads": {
            fontSize: 14,
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "&.sads": {},
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: { "&.sads": { fontSize: 14 } },
      },
    },

    MuiTooltip: {
      defaultProps: {
        arrow: true,
        // 툴팁 내부 padding, 기본 fontSize 스타일링
        componentsProps: {
          tooltip: {
            sx: { p: 1, fontSize: 14 },
          },
        },
      },
      styleOverrides: {
        tooltip: { "&.sads": {} },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.sads": {
            boxShadow: "none",
            border: "1px solid #BEBEBE",

            "&.MuiAccordion-root:before": {
              background: "none",
            },
          },
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.sads": {
            fontSize: 20,
            fontWeight: 600,
          },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: { "&.sads": {} },
      },
    },
  },
});

export default theme;
